<script>
import Common from "@/components/common";
export default {
  components: {
    Common
  },
  data() {
    return {
      userData:{}
    };
  },
  mounted() {
    this.userData = this.$refs.commonFunc.getFullData()
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="48" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="48" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.png" alt height="42" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="42" />
            </span>
          </router-link>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-1 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        
      </div>

      <div class="d-flex">
        <a href="https://t.me/zpayannouncement" target="_blank" class="my-auto fw-medium me-2 d-flex align-items-center" v-b-tooltip.hover title="Announcement"> <i class="uil uil-bell font-size-22 me-lg-1"></i> <span class="d-none d-md-flex">Announcement</span></a>
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/user.png"
              alt="Header Avatar"
            />
            <span
              class="ms-1 fw-medium font-size-15"
              > {{userData.name}}</span
            >
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </template>

         
          <!-- <router-link to="/" class="dropdown-item d-block">
            <i
              class="uil uil-cog font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">Activity Log</span>
          </router-link> -->
          <router-link  :to="{ name: 'profile'}" class="dropdown-item">
            <i
              class="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">Change Password</span>
          </router-link>
          <router-link :to="{ name: 'logout'}"  class="dropdown-item">
            <i
              class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">{{$t("siteLang.Logout") }}</span>
          </router-link>
        </b-dropdown>

      </div>
    </div>
    <Common ref="commonFunc"/>
  </header>
</template>
